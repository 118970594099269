import _ from 'lodash';

class BaseResponse {
    /**
     * @type object
     */
    response;

    constructor(response) {
        this.response = response;
    }

    getCode() {
        return this.response.status;
    }

    getBody() {
        return this.response.request.response;
    }

    getData() {
        return this.response.data;
    }

    getValue(name, defaultValue = null) {
        return _.get(this.getData(), name, defaultValue);
    }

}

export default BaseResponse;
