import BaseClient from "@api/BaseClient";

class User extends BaseClient {

  /**
   * Login
   * @param {object} options
   * {
   * 	"username": "jack",
   * 	"password": "123456"
   * }
   * @returns {Promise<*>}
   */
  async login(options = {}) {
    const response = await this.requestPost(`/login`, options);
    return response.getData();
  }

  /**
   * Logout
   * @returns {Promise<*>}
   */
  async logout() {
    const response = await this.requestGet(`/logout`);
    return response.getData();
  }

  /**
   * Profile
   * @param {string} id
   * @returns {Promise<*>}
   */
  async profile(id) {
    const response = await this.requestGet(`/user/profile`);
    return response.getData();
  }

}

export default User;
