import React  from 'react';
import { Container, Flex, Spinner } from '@chakra-ui/react';

export default function LazySpinner({ color = 'blue'}) {
  let spinnerEmptyColor = 'gray.200';
  let spinnerColor = 'blue.500';
  if (color === 'yellow') {
    spinnerColor = 'yellow.500';
  }
  return (
    <Container
      bg='gray.50'
      maxW='100vw'
      h='100vh'
      as={Flex}
      justifyContent='center'
      alignItems='center'
    >
      <Spinner
        thickness='4px'
        speed='0.4s'
        emptyColor={spinnerEmptyColor}
        color={spinnerColor}
        size='xl'
      />
    </Container>
  );
}
