import BaseClient from "@api/BaseClient";

class Drive extends BaseClient {

  /**
   * List
   * @param {object} options
   * {
   * 	"path": "/我的下载",
   * 	"page": 1,
   * 	"pageSize": 120,
   * 	"sort": "ModifiedAtHighToLow",
   * 	"filter": {
   * 		"name": "",
   * 		"nameType": "Contains",
   * 		"fileType": "All",
   * 		"extensions": []
   * 	}
   * }
   * @returns {Promise<*>}
   */
  async list(options = {}) {
    const response = await this.requestPost(`/drive/list`, options);
    return response.getData();
  }

  /**
   * Get
   * @param {string} id
   * @returns {Promise<*>}
   */
  async get(id) {
    const response = await this.requestGet(`/drive/files/${id}`);
    return response.getData();
  }

  /**
   * View
   * @param {string} id
   * @returns {Promise<*>}
   */
  async view(id) {
    const response = await this.requestGet(`/drive/files/${id}/view`);
    return response.getData();
  }

  /**
   * Create
   * @param {object} options
   * {
   * 	"dir": "/my/视频/sony"
   * }
   * @returns {Promise<*>}
   */
  async create(options = {}) {
    const response = await this.requestPost(`/drive/files`, options);
    return response.getData();
  }

  /**
   * Add or upload
   * @param {object} options
   * {
   * 	"path": "/我的上传/a.mp4",
   * 	"firstMd5": "236c1b87a8e3776627fcfc90b32288f1",
   * 	"lastMd5": "ad61cb67ee041661f650fa3f6d4eb10d",
   * 	"md5": "7ede2d3c482f27dadc5d1be5e8b8adf3",
   * 	"size": 309447
   * }
   * @returns {Promise<*>}
   */
  async addOrUpload(options = {}) {
    const response = await this.requestPost(`/drive/file/add-or-upload`, options);
    return response.getData();
  }

  /**
   * Complete upload
   * @param {object} options
   * {
   *  "data":"...",
   *  "started":1658829076,
   *  "expired":1658829196,
   *  "sign":"b0559e84be22cb885006311e64c59e29"
   * }
   * @returns {Promise<*>}
   */
  async completeUpload(options = {}) {
    const response = await this.requestPost(`/drive/file/complete-upload`, options);
    return response.getData();
  }

  /**
   * Move
   * @param {object} options
   * {
   * 	"source": "/my/视频/sony",
   * 	"dest": "/sony-new"
   * }
   * @returns {Promise<*>}
   */
  async move(options = {}) {
    const response = await this.requestPut(`/drive/files`, options);
    return response.getData();
  }

  /**
   * Delete
   * @param {object} options
   * {
   * 	"path": "/sony-new"
   * }
   * @returns {Promise<*>}
   */
  async delete(options = {}) {
    const response = await this.requestDelete(`/drive/files`, options);
    return response.getData();
  }

  getFileUrl(fileId) {
    return this.getFullUrl(`/drive/files/${fileId}/download`)
  }

}

export default Drive;
