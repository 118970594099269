// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from '@chakra-ui/react';

function getSize(value) {
  if (value === 'full') {
    return {
      popper: {
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available',
        },
        zIndex: 10000,
        transform: 'none!important',
      },
      content: {
        w: '100vw',
        h: '100vh',
        m: 0,
        borderRadius: 0,
      },
      body: {
        flex: '1 1 0',
      },
    };
  }
  return {
    popper: { maxW: value },
  };
}

const theme = extendTheme({
  components: {
    Button: {
      // 3. We can add a new visual variant
      variants: {},
    },

    Popover: {
      variants: {
        responsive: {
          popper: {
            maxWidth: 'unset',
            width: 'unset',
          },
        },
        full: getSize('full'),

      },
    },

  },
});

export default theme;
