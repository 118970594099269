import BaseClient from "@api/BaseClient";

class Captcha extends BaseClient {

  /**
   * Get image url
   * @returns {Promise<*>}
   */
  getImageUrl(id) {
    return this.getFullUrl(`/captcha/${id}.png`)
  }

  /**
   * Get audio url
   * @returns {Promise<*>}
   */
  getAudioUrl(id) {
    return this.getFullUrl(`/captcha/${id}.wav`)
  }

  /**
   * Reload captcha
   * @returns {Promise<*>}
   */
  async reloadCaptcha() {
    const response = await this.requestPost(`/captcha`, {
      update: true,
    });
    return response.getData();
  }

}

export default Captcha;
