import BaseClient from "@api/BaseClient";

class Drive extends BaseClient {

  /**
   * List
   * @param {object} options
   * {
   * 	"page": 1,
   * 	"pageSize": 2,
   * 	"sort": "name",
   * 	"sortOrder": "desc",
   * 	"filter": {
   * 		"name": "",
   * 		"nameType": "contains"
   * 	}
   * }
   * @returns {Promise<*>}
   */
  async list(options = {}) {
    const response = await this.requestPost(`/download/list`, options);
    return response.getData();
  }

  /**
   * Get
   * @returns {Promise<*>}
   */
  async get(id) {
    const response = await this.requestGet(`/downloads/${id}`);
    return response.getData();
  }

  /**
   * Create
   * @param {object} options
   * {
   * 	"url": "https://www.youtube.com/watch?v=7VzDrqjhcXs"
   * }
   * @returns {Promise<*>}
   */
  async create(options = {}) {
    const response = await this.requestPost(`/downloads`, options);
    return response.getData();
  }

  /**
   * Delete
   * @param {object} options
   * {
   * 	"ids": ["1", "2"]
   * }
   * @returns {Promise<*>}
   */
  async delete(options = {}) {
    const response = await this.requestDelete(`/downloads`, options);
    return response.getData();
  }

}

export default Drive;
